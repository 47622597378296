import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

const year = new Date().getFullYear();

// Select copyright element
// const copyright = document.querySelector('.footer__text');
const copyright = document.querySelector('.footer-text.mb-0');

// Update innerHTML with new year
copyright.innerHTML = `
  © ${year} - Developed by 
  <a rel="noreferrer" href="https://github.com/JLLoppez" target="_blank">
    JAL Lopes
  </a>
`;

// Get reference to button
// const notifyBtn = document.getElementById('notifyBtn');

// // Add click event listener
// notifyBtn.addEventListener('click', () => {

//   // Show alert
//   Bert.alert('Thank you! Your email has been saved and will be notified soon!!');

// });

// Get loader element
initScrollReveal(targetElements, defaultProps);
initTiltEffect();
